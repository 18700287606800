import React, { useState } from "react";
import Modal from "./Modal";
import {AddressIcon, EmailIcon, PhoneIcon, UserIcon} from "../icons/customer";
import { BasicField } from "./NutricalRequestFormModal";
import {ForkKnifeIcon, CodeBlockIcon} from "../icons";
import Button from "./Button";
import { saveRestaurant } from "../service/RestaurantService";
import ClientRequestFileUpload from "./ClientRequestFileUpload";

const restaurantDefault = {
  contact_email: "",
  contact_name: "",
  contact_phone: "",
  external_id: "",
  id: null,
  name: "",
  restaurant_files: [
    // {
    //   "id": 0,
    //   "storage": {
    //     "id": "3fa85f64-5717-4562-b3fc-2c963f66afa6"
    //   }
    // }
  ],
};

const RestaurantForm = ({ onClose, onSubmit }) => {
  const [restaurant, setRestaurant] = useState(restaurantDefault);

  const isValidEmail = (email) => {
    if (!email) {
      return true;
    }

    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };

  const validateForm = () => {
    let validation = {
      isValid: true,
      name: {
        errorMsg: "",
      },
      contact_email: {
        errorMsg: "",
      },
    };

    if (!restaurant.name) {
      validation.isValid = false;
    }

    if (restaurant.name && restaurant.name.length < 3) {
      validation.name.errorMsg = "Minimalan broj karaktera: 3";
      validation.isValid = false;
    }

    if (!isValidEmail(restaurant.contact_email)) {
      validation.contact_email.errorMsg = "Unesite ispravan format";
      validation.isValid = false;
    }

    return validation;
  };

  const validation = validateForm();

  const handleNameChange = (e) => {
    setRestaurant({ ...restaurant, name: e.target.value });
  };

  const handleAddressChange = (e) => {
    setRestaurant({ ...restaurant, address: e.target.value });
  };

  const handleContactNameChange = (e) => {
    setRestaurant({ ...restaurant, contact_name: e.target.value });
  };

  const handleContactPhoneChange = (e) => {
    setRestaurant({ ...restaurant, contact_phone: e.target.value });
  };

  const handleContactEmailChange = (e) => {
    setRestaurant({ ...restaurant, contact_email: e.target.value });
  };

  const handleSubmit = () => {
    saveRestaurant(restaurant)
      .then(() => {
        onSubmit();
      })
      .catch((error) => {
        alert("Došlo je do greške");
      });
  };

  return (
    <div>
      <Modal
        contentClassName="basis-[550px] bg-white"
        show={true}
        onClose={onClose}
      >
        <Modal.Header className="text-left w-full ">
          Kreiraj klijenta
        </Modal.Header>

        <Modal.Body className="w-full">
          <BasicField
            icon={<ForkKnifeIcon />}
            label="Ime klijenta"
            value={restaurant.name}
            onChange={handleNameChange}
            mandatory={true}
            errorMsg={validation.name.errorMsg}
          />
          <BasicField
            icon={<AddressIcon />}
            label="Adresa"
            value={restaurant.address}
            onChange={handleAddressChange}
            mandatory={false}
            errorMsg={validation?.address?.errorMsg}
          />

          <BasicField
            icon={<UserIcon />}
            label="Ime kontakt osobe"
            value={restaurant.contact_name}
            onChange={handleContactNameChange}
            mandatory={false}
            errorMsg={validation?.contact_name?.errorMsg}
          />

          <BasicField
            icon={<PhoneIcon />}
            label="Broj telefona"
            value={restaurant.contact_phone}
            onChange={handleContactPhoneChange}
            mandatory={false}
            errorMsg={validation?.contact_phone?.errorMsg}
          />

          <BasicField
            icon={<EmailIcon />}
            label="Email"
            value={restaurant.contact_email}
            onChange={handleContactEmailChange}
            mandatory={false}
            errorMsg={validation?.contact_email?.errorMsg}
          />

          <ClientRequestFileUpload
            clientRequest={restaurant}
            setClientRequest={setRestaurant}
          />
        </Modal.Body>
        <Modal.Footer
          className="flex justify-center p-8"
          isValid={validation.isValid}
        >
          <Button className="my-4" size="large" onClick={handleSubmit}>
            Kreiraj klijenta
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default RestaurantForm;
