import React, {useEffect, useState} from "react";
import Navigation from "../components/Navigation";
import {
    getMenuPDF,
    getMenuZip,
    getRestaurant,
    getRestaurantRequestMeals,
    reorderRestaurantRequests,
    updateRestaurant,
    updateRestaurantStatus,
} from "../service/RestaurantService";
import {useNavigate, useParams} from "react-router-dom";
import Expandable from "../components/Expandable";
import TextField from "../components/TextField";
import NextStep from "../components/NextStep";
import Button from "../components/Button";
import {DownloadIcon, EditIcon, EyeIcon, SaveIcon} from "../icons";
import {DocType} from "../hooks/useRecipeReport";
import Loader from "../components/Loader";
import {executeDownload} from "../service/HtmlUtils";
import {DndProvider, useDrag, useDrop} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";
import classNames from "classnames";
import MealCategorySelect from "../components/MealCategorySelect";
import MealCategoriesModal from "../components/MealCategoriesModal";
import Checkmark from "../components/Checkmark";
import NumberField from "../components/NumberField";
import {useMealCategories} from "../store/MealCategoriesStore";
import {useRestaurantMeals, useRestaurantMealUpdate} from "../store/RestaurantRequestStore";
import _ from "lodash";

const useSorting = (categories, requests) => {

    const [requestsByCategory, setRequestsByCategory] = useState({})

    useEffect(() => {
        let map = {}

        if (!requests) {
            return
        }

        requests.forEach(request => {
            let categoryId = request?.meal_category?.id ?? '0'

            if (!map[categoryId]) {
                map[categoryId] = [];
            }

            map[categoryId].push(request);
        })

        setRequestsByCategory(map)


    }, [categories, requests]);

    return {
        requestsByCategory,
        setRequestsByCategory
    }
}

const RestaurantMenuContainer = () => {
    const navigate = useNavigate();
    const {clientId} = useParams();
    const [restaurant, setRestaurant] = useState(null);

    const {data: meals} = useRestaurantMeals(clientId)
    const {mutate: updateMeals} = useRestaurantMealUpdate(clientId)

    const [restaurantRequests, setRestaurantRequests] = useState([]);

    const [isLoading, setLoading] = useState(false);
    const {data: categories} = useMealCategories(clientId)
    const [showCategoriesModal, setShowCategoriesModal] = useState(false)
    const {requestsByCategory, setRequestsByCategory} = useSorting(categories, meals)

    useEffect(() => {
        getRestaurant(clientId)
            .then((response) => setRestaurant(response.data))
            .catch((err) => alert("Server error"));

        getRestaurantRequestMeals(clientId)
            .then((response) => {
                    setRestaurantRequests(response.data.restaurant_requests)
                }
            )
            .catch((err) => alert("Server error"));


    }, [clientId]);

    const moveItem = (categoryId, fromIndex, toIndex) => {
        const newItems = [...requestsByCategory[categoryId]];
        const [movedItem] = newItems.splice(fromIndex, 1);
        newItems.splice(toIndex, 0, movedItem);
        setRequestsByCategory({
            ...requestsByCategory,
            [categoryId]: newItems
        });

        reorderRestaurantRequests(clientId, newItems.map((item, index) => ({
            restaurant_request_id: item.id,
            position: index
        })))
    };

    const handlePdfPreviewBtnClick = () => {
        navigate("preview");
    };

    const handleRestaurantNameChange = (e) => {
        let restaurantLocal = {
            ...restaurant,
            name: e.target.value,
        };

        setRestaurant(restaurantLocal);
        updateRestaurant(restaurantLocal);
    };

    const toggleRestaurantReportFlag = (name) => {

        let restaurantReportFlagLocal = restaurant.restaurant_report_flag ? restaurant.restaurant_report_flag : {}

        let restaurantLocal = {
            ...restaurant,
            restaurant_report_flag: {
                ...restaurantReportFlagLocal,
                [name]: !restaurantReportFlagLocal?.[name]
            }
        }

        setRestaurant(restaurantLocal);
        updateRestaurant(restaurantLocal);
    };

    const handleCompleteBtnClick = () => {
        updateRestaurantStatus(clientId, "DONE");
        navigate(-2);
    };

    const handleDownloadBtnClick = () => {
        setLoading(true);

        getMenuPDF(clientId)
            .then((pdf) => {
                executeDownload(pdf, `${clientId}-jelovnik.pdf`);
            })
            .catch((err) => {
                console.log(err);
                alert("Server error");
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleDownloadZipBtnClick = () => {
        setLoading(true);

        getMenuZip(clientId)
            .then((pdf) => {
                executeDownload(pdf, `${clientId}-jelovnik.zip`);
            })
            .catch((err) => {
                console.log(err);
                alert("Server error");
            })
            .finally(() => {
                setLoading(false);
            });
    };


    return (
        <>
            <div className="flex flex-col items-center justify-center mt-[20px] p-8 w-full">
                <Navigation/>

                <div className="flex items-start w-full">
                    <div className="grow">
                        <Expandable className="w-full" title="Restoran">
                            <div className="flex p-4">
                                <TextField
                                    className="grow"
                                    label="Naziv restorana"
                                    value={restaurant?.name}
                                    onChange={handleRestaurantNameChange}
                                />
                            </div>
                        </Expandable>
                        <div className="mt-4">
                            <Button color="secondary" startIcon={<EditIcon width={16}/>}
                                    onClick={() => setShowCategoriesModal(true)}>
                                <div className="font-light">Kategorije</div>
                            </Button>
                        </div>
                        {
                            categories &&
                            categories
                                .sort((a, b) => a.index > b.index)
                                .filter(category => requestsByCategory[category.id] && requestsByCategory[category.id].length > 0)
                                .map((category, categoryIndex) => (
                                    <Expandable key={category.id} className="w-full mt-[20px] mb-12"
                                                title={category?.category}>
                                        {
                                            requestsByCategory[category.id] &&
                                            requestsByCategory[category.id].length > 0 && (
                                                <DndProvider backend={HTML5Backend}>
                                                    <table className="w-full border-collapse table-fixed">
                                                        <thead
                                                            className="px-4 text-primary opacity-70 text-sm h-[42px] border-b border-b-gray-100">
                                                        <tr>
                                                            <th className="w-[60px] text-center"></th>
                                                            <th className="px-4 text-left font-light">
                                                                Naziv recepta
                                                            </th>
                                                            <th className="px-4 text-left font-light w-[160px]">Cena</th>
                                                            <th className="w-[50px] text-right"></th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {
                                                            requestsByCategory[category.id]
                                                                .sort((request) => request.position)
                                                                .map((request, index) => (
                                                                    <DraggableItem
                                                                        key={request.id}
                                                                        request={request}
                                                                        onMealUpdate={updateMeals}
                                                                        index={index}
                                                                        moveItem={(fromIndex, toIndex) => moveItem(category.id, fromIndex, toIndex)}
                                                                        categoryId={category.id}
                                                                    />
                                                                ))}
                                                        </tbody>
                                                    </table>
                                                </DndProvider>
                                            )
                                        }


                                    </Expandable>
                                ))
                        }
                        <Expandable className="w-full mt-[20px] mb-12" title="Nekategorizovani recepti">
                            <div className="">
                                <DndProvider backend={HTML5Backend}>
                                    <table className="w-full border-collapse table-fixed">
                                        <thead
                                            className="px-4 text-primary opacity-70 text-sm h-[42px] border-b border-b-gray-100">
                                        <tr>
                                            <th className="w-[60px] text-center"></th>
                                            <th className="px-4 text-left font-light ">
                                                Naziv recepta
                                            </th>
                                            <th className="px-4 text-left font-light w-[220px]">Kategorija</th>
                                            <th className="px-4 text-center font-light w-[180px]">Cena</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {meals && meals
                                            .sort((request) => request.id)
                                            .filter(request => !request.meal_category)
                                            .map((request, index) => (
                                                <UncategorizedItem
                                                    key={request.id}
                                                    request={request}
                                                    onMealUpdate={updateMeals}
                                                    categories={categories}
                                                    clientId={clientId}
                                                />
                                            ))}
                                        </tbody>
                                    </table>
                                </DndProvider>
                            </div>
                        </Expandable>

                    </div>

                    <div
                        className="text-sm p-[24px] text-primary w-[350px] flex-shrink-0 bg-white rounded-[8px] ml-8 flex flex-col">
                        <div className="mt-2">
                            <span>Podnosilac zahteva: </span>
                            <span className="font-semibold ml-2">{restaurant?.contact_name}</span>
                        </div>

                        <div className="mt-2">
                            {
                                [
                                    {
                                        label: 'Izveštaj Zbirni PDF',
                                        value: 'restaurant_report'
                                    },
                                    {
                                        label: 'Izveštaj Pojedinačni PDF',
                                        value: 'regular_report'
                                    },
                                    {
                                        label: 'Izveštaj Proizvodjačka spec',
                                        value: 'print_report'
                                    },
                                    {
                                        label: 'Izveštaj Deklaracija za jelo',
                                        value: 'recipe_declaration_report'
                                    },
                                    {
                                        label: 'Interna deklaracija za subrecept',
                                        value: 'subrecipe_declaration_report'
                                    },
                                    {
                                        label: 'QR kod',
                                        value: 'qr_code'
                                    },
                                    {
                                        label: 'Prikaži cenu',
                                        value: 'price'
                                    },
                                ].map(e => (
                                    <div key={e.value}
                                         className="flex items-center mt-2 hover:cursor-pointer hover:underline"
                                         onClick={() => toggleRestaurantReportFlag(e.value)}>
                                        <Checkmark size="sm" checked={restaurant?.restaurant_report_flag?.[e.value]}/>
                                        <div className="ml-2">{e.label}</div>
                                    </div>
                                ))
                            }

                        </div>

                        <div className="flex flex-col">
                            <button
                                onClick={() => handlePdfPreviewBtnClick(DocType.REGULAR)}
                                className={`h-[32px] rounded-[4px] bg-section-header
                                                mt-[16px] font-semibold
                                                flex items-center justify-center
                                                hover:underline
                                              `}
                            >
                                <EyeIcon fill="black" width={16}/>
                                <div className="ml-1">Preview zbirnog izveštaja</div>
                            </button>
                        </div>

                        <div className="flex flex-col">
                            <button
                                onClick={handleDownloadBtnClick}
                                className={`h-[32px] rounded-[4px] bg-section-header
                                                mt-[16px] font-semibold
                                                flex items-center justify-center
                                                hover:underline`}
                            >
                                <DownloadIcon stroke="black" width={16}/>
                                <div className="ml-1">Preuzimanje zbirnog izveštaja</div>
                            </button>
                        </div>
                        <div className="flex flex-col">
                            <button
                                onClick={handleDownloadZipBtnClick}
                                className={`h-[32px] rounded-[4px] bg-primary-action
                                                mt-[16px] font-semibold text-white
                                                flex items-center justify-center
                                                hover:opacity-80`}
                            >
                                <DownloadIcon stroke="white" width={16}/>
                                <div className="ml-1">Preuzimanje jelovnika .zip</div>
                            </button>
                        </div>
                    </div>
                </div>
                <NextStep
                    onNext={handleCompleteBtnClick}
                    rightButton={
                        <Button color="green" onClick={handleCompleteBtnClick}>
                            <SaveIcon/>
                            <span className="ml-1">Završi</span>
                        </Button>
                    }
                />
            </div>
            <Loader isLoading={isLoading}/>
            {showCategoriesModal &&
                <MealCategoriesModal clientId={clientId}
                                     onClose={() => setShowCategoriesModal(false)}/>
            }
        </>
    );
};

const UncategorizedItem = ({request, onMealUpdate, clientId, categories}) => {

    const handleMealCategoryChange = (category) => {
        onMealUpdate({
            ...request,
            meal_category: category
        })
    }



    const handlePriceChange = (e) => {
        onMealUpdate({
            ...request,
            price: e.target.value
        })
    }

    const toggleSelection = () => {
        onMealUpdate({
            ...request,
            is_selected: !request.is_selected
        })
    }

    return (
        <tr
            key={request.id}
            id={request.id}
            className={classNames("border-b border-b-gray-100 hover:bg-gray-100", {})}
        >
            <td className="text-center py-2">
                <div className="flex items-center justify-center" onClick={toggleSelection}>
                    <Checkmark checked={request.is_selected}/>
                </div>
            </td>
            <td className="px-4  py-2 align-middle">
                <div className="flex flex-col items-start">
                    <div className="text-primary font-medium text-sm">
                        {request.recipe_name}
                    </div>
                    <div
                        className="mt-1 text-sm"
                        dangerouslySetInnerHTML={{
                            __html: request.ingredient_text,
                        }}
                    />
                    {request.allergens && request.allergens.length > 0 &&
                        <div
                            className="min-h-[30px] bg-input-disabled text-sm py-2 px-4 text-light rounded-[8px] mt-2 shrink">
                            {request.allergens.map((e) => e.allergen_label).join(", ")}
                        </div>
                    }
                </div>
            </td>
            <td className="px-4 py-2">
                <MealCategorySelect id={request.id}
                                    clientId={clientId}
                                    categories={categories}
                                    onChange={handleMealCategoryChange}
                />
            </td>
            <td className="px-4 py-2">
                <div className="flex items-center w-full">
                    <NumberField value={request.price ?? ''}
                                 onChange={handlePriceChange} inputClassName="w-[100px] mr-2"/>
                    <div className="text-light text-sm">RSD</div>
                </div>
            </td>
        </tr>
    );
};

const DraggableItem = ({request, onMealUpdate, index, moveItem, categoryId}) => {
    const [{isDragging}, drag] = useDrag({
        type: `category-${categoryId}`,
        item: {index},
        collect: (monitor) => ({
            isDragging: !!monitor.isDragging(),
        }),
        snapToGrid: 60,
    });

    const [{isOver}, drop] = useDrop({
        accept: `category-${categoryId}`,
        drop: (draggedItem) => {
            if (draggedItem.index !== index) {
                moveItem(draggedItem.index, index);
                draggedItem.index = index;
            }
        },
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
    });

    const handleClearMealCategory = () => {
        onMealUpdate({
            ...request,
            meal_category: null
        })
    }

    const handlePriceChange = (e) => {
        onMealUpdate({
            ...request,
            price: e.target.value
        })
    }

    const toggleSelection = () => {
        onMealUpdate({
            ...request,
            is_selected: !request.is_selected
        })
    }

    return (
        <tr
            ref={(node) => drag(drop(node))}
            key={request.id}
            id={request.id}
            className={classNames("border-b border-b-gray-100 hover:bg-gray-100", {
                "!cursor-grabbing": isDragging,
                "hover:cursor-grab": !isDragging,
                "bg-gray-900 opacity-10": isOver,
            })}
            draggable={true}
        >
            <td className="px-4 py-2">
                <div className="w-full flex items-center justify-center" onClick={toggleSelection}>
                    <Checkmark checked={request.is_selected}/>
                </div>
            </td>
            <td className="px-4 py-2 align-middle">
                <div className="flex flex-col items-start justify-center ">
                    <div className="text-primary font-medium text-sm">
                        {request.recipe_name}
                    </div>
                    {
                        request.ingredient_text &&
                        <div
                            className="mt-1 text-sm"
                            dangerouslySetInnerHTML={{
                                __html: request.ingredient_text,
                            }}
                        />
                    }
                    {request.allergens && request.allergens.length > 0 &&
                        <div
                            className="min-h-[30px] bg-gray-200 text-sm py-2 px-4 text-light rounded-[6px] mt-2 shrink">
                            {request.allergens.map((e) => e.allergen_label).join(", ")}
                        </div>
                    }
                </div>
            </td>

            <td className="px-4 py-2">
                <div className="flex items-center w-full">
                    <NumberField value={request.price ?? ''} onChange={handlePriceChange}
                                 inputClassName="w-[100px] mr-2"/>
                    <div className="text-light text-sm">RSD</div>
                </div>
            </td>

            <td className="px-4 py-2">
                <div
                    className="w-[25px] h-[25px] rounded-full flex items-center justify-center hover:bg-gray-200 hover:cursor-pointer text-gray-400 text-[18px]"
                    onClick={handleClearMealCategory}>x
                </div>
            </td>
        </tr>
    );
};

export default RestaurantMenuContainer;
