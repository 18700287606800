import axios from "axios";
import {
  host,
  recipe_report_download_url,
  recipe_report_generate_url,
} from "../config/api";
import { executeDownload } from "./HtmlUtils";

export const Allergens = {
  CELERY: {
    label: "Celer",
    key: "CELERY",
  },
  CRUSTACEANS: {
    label: "Rakovi",
    key: "CRUSTACEANS",
  },
  EGGS: {
    label: "Jaja",
    key: "EGGS",
  },
  FISH: {
    label: "Riba",
    key: "FISH",
  },
  GLUTEN: {
    label: "Gluten",
    key: "GLUTEN",
  },
  LUPIN: {
    label: "Lupin",
    key: "LUPIN",
  },
  MILK: {
    label: "Mleko",
    key: "MILK",
  },
  MOLLUSCS: {
    label: "Mekušci",
    key: "MOLLUSCS",
  },
  MUSTARD: {
    label: "Slačica",
    key: "MUSTARD",
  },
  NUTS: {
    label: "Orašasti plodovi",
    key: "NUTS",
  },
  PEANUTS: {
    label: "Kikiriki",
    key: "PEANUTS",
  },
  SESAME_SEEDS: {
    label: "Seme susama",
    key: "SESAME_SEEDS",
  },
  SOYBEANS: {
    label: "Soja",
    key: "SOYBEANS",
  },
  SULPHURE_DIOXIDE_SULPHITES: {
    label: "Sumpor Dioksid i sulfiti",
    key: "SULPHURE_DIOXIDE_SULPHITES",
  },
};

export const MealCharacteristic = {
  FASTING: {
    label: "Posno",
    key: "fasting",
  },
  VEGAN: {
    label: "Vegansko",
    key: "vegan"
  },
  VEGETARIAN: {
    label: "Vegeterijansko",
    key: "vegetarian"
  },
  OTHER: {
    label: "Drugo:",
    key: "other"
  }
}

export const getRestaurant = (restaurantId) => {
  return axios({
    method: "GET",
    url: `${host}/v1/restaurants/${restaurantId}`,
  });
};

export const getRestaurants = (params) => {
  return axios({
    method: "GET",
    url: `${host}/v1/restaurants`,
    params: params,
  }).then((response) => {
    return response.data;
  });
};

export const deleteRestaurant = (id) => {
  return axios({
    method: "DELETE",
    url: `${host}/v1/restaurants/${id}`,
  });
};

export const updateRestaurantStatus = (restaurantId, status) => {
  return axios({
    method: "POST",
    url: `${host}/v1/restaurants/${restaurantId}/update-status/${status}`,
  });
};

export const getRestaurantRequests = (params) => {
  return axios({
    method: "GET",
    url: `${host}/v1/restaurant-requests`,
    params: params,
  });
};

export const getRestaurantRequest = (restaurantId) => {
  return axios({
    method: "GET",
    url: `${host}/v1/restaurant-requests/${restaurantId}`,
  });
};

export const deleteRestaurantRequest = (restaurantRequestId) => {
  return axios({
    method: "DELETE",
    url: `${host}/v1/restaurant-requests/${restaurantRequestId}`,
  });
};

export const saveRestaurant = (restaurant) => {
  return axios({
    method: "POST",
    url: `${host}/v1/restaurants`,
    data: restaurant,
  });
};

export const updateRestaurant = (restaurant) => {
  return axios({
    method: "POST",
    url: `${host}/v1/restaurants/${restaurant.id}`,
    data: restaurant,
  });
};

export const saveRestaurantRequest = (restaurantRequest) => {
  return axios({
    method: "POST",
    url: `${host}/v1/restaurant-requests`,
    data: restaurantRequest,
  });
};

export const updateRestaurantRequest = (restaurantRequest) => {
  return axios({
    method: "POST",
    url: `${host}/v1/restaurant-requests/${restaurantRequest.id}`,
    data: restaurantRequest,
  });
};

export const getRestaurantRequestMeals = (restaurantId) => {
  return axios({
    method: "GET",
    url: `${host}/v1/restaurant-requests/allergens`,
    params: {
      "restaurant-id": restaurantId,
    },
  });
};

export const updateRestaurantRequestMeal = (meal) => {
  return axios({
    method: "POST",
    url: `${host}/v1/restaurant-requests/update-category`,
    data: meal
  });
};

export const updateRestaurantRequestExternalId = (requestId, externalId) => {
  return axios({
    method: "POST",
    url: `${host}/v1/restaurant-requests/update-external-id`,
    data: {
      id: requestId,
      external_id: externalId,
    },
  });
};

export const updateRestaurantRequestMealCategory = (requestId, mealCategoryId) => {
  return axios({
    method: "POST",
    url: `${host}/v1/restaurant-requests/update-category`,
    data: {
      id: requestId,
      meal_category: {
        id: mealCategoryId
      },
    },
  });
};



export const clearRestaurantRequestMealCategory = (requestId) => {
  return axios({
    method: "POST",
    url: `${host}/v1/restaurant-requests/update-category`,
    data: {
      id: requestId,
      meal_category: null
    },
  });
};

export const reorderRestaurantRequests = (requestId, data) => {
  return axios({
    method: "POST",
    url: `${host}/v1/restaurant-requests/reorder/restaurants/${requestId}`,
    data: {
      restaurant_request_orders: data
    },
  });
};

export const updateRestaurantRequestStatus = (requestId, status) => {
  return axios({
    method: "POST",
    url: `${host}/v1/restaurant-requests/${requestId}/update-status/${status}`,
  });
};

export const updateRestaurantRequestName = (requestId, name) => {
  return axios({
    method: "POST",
    url: `${host}/v1/restaurant-requests/${requestId}/update-name`,
    params: {
      name: name
    }
  });
};

export const getMenuPDF = async (restaurantId) => {
  return axios({
    method: "GET",
    url: `${host}/v1/restaurant-reports/download/restaurant/${restaurantId}`,
    params: {
      type: "RESTAURANT",
    },
    responseType: "blob",
  }).then((response) => {
    return new Blob([response.data], { type: "application/pdf" });
  });
};

export const getMenuZip = async (restaurantId) => {
  return axios({
    method: "GET",
    url: `${host}/v1/restaurant-reports/download/restaurant/${restaurantId}/zip`,
    params: {
      type: "RESTAURANT",
    },
    responseType: "blob",
  }).then((response) => {
    return new Blob([response.data], { type: "application/pdf" });
  });
};
